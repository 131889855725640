import subjectCategory from '@/modules/strubs/subjectCategory'

const subject = {
  id: 0,
  name: '',
  value: '',
  value_with_codes: '',
  active: false,
  updated_at: '',
  created_at: '',
  category: subjectCategory,
  metadata: {
    editable: {
      name: false,
      value: false
    }
  }
}

export default subject
