<template>
  <em class="text-info" v-if="!value">{{ text }}</em>
  <span v-else>
    <template v-if="$slots['value']">
      <slot name="value"/>
    </template>
    <template v-else>{{ value }}</template>
  </span>
</template>

<script>
export default {
  name: 'Nullable',
  props: {
    value: { type: [String, Object, Number], default: undefined },
    text: { type: String, default: 'None' }
  }
}
</script>
